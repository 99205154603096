<template>
<div class="inner-template">
    <h1 class="ml-4 my-4">Dashboard</h1>
    <div class="overview component" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <v-container fill-height fluid class="align-start">
      <v-card color="white" class="ma-0 col-12 mb-2" elevation="4">
        <div class="mb-4">
          <!-- <h5 class="app-heading mb-1">Your Ratings</h5> -->
          <!-- <h5 class="app-heading mb-1"><v-icon class="mr-2" color="secondary">mdi-chart-timeline-variant-shimmer</v-icon>Your Ratings</h5> -->
          <v-alert
          color="grey darken-1"
          icon="mdi-chart-timeline-variant-shimmer"
          text>
          <h4 class="secondary--text">No ratings yet</h4>
          <p class="text-body-2">After meetings, others will rate your skills and those will appear here.</p>
          <hr class="my-3">
          <p class="text-body-2">Post your link below on social media and websites to attract people to meet with!</p>
          <v-sheet>
            <strong class="primary--text">https://meetable.me/@{{user.userName}}</strong>
          </v-sheet>
          </v-alert>
        </div>

        <div class="skills mx-0 col-12 pa-0">
          <h5 class="app-heading mb-1">My Ratings</h5>
          <v-row no-gutters class="text--disabled text-caption">
            <v-col cols="4">skill / interest</v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" class="text-center">meetings</v-col>
            <v-col cols="2"  class="text-center">rating</v-col>
          </v-row>
          <v-row class="skill pa-1" no-gutters v-for="(skill, index) in sortedSkills" :key="`skill-${index}`" align="center" justify="start">
            <v-col cols="4">
                <v-chip v-if="skill.new" class="" color="primary">{{skill.name}}</v-chip>
                <v-chip v-else class="" dark color="black">{{skill.name}}</v-chip>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" align-items="center" class="text-center">
              <v-icon class="mr-1" size="16" color="grey">mdi-handshake-outline</v-icon>{{skill.meetings || 0}} <br>
            </v-col>
            <v-col cols="2" align-items="center" class="text-center"><v-icon class="mr-1" size="16" color="grey">mdi-arrow-up-down</v-icon>{{skill.rating || 0}}</v-col>
          </v-row>
        </div>
      </v-card>
      </v-container>
    </div>
</div>
</template>

<script>

import { cloud, db } from '@/firebase';
import { mapGetters } from 'vuex';
// const meetingItem = () => import("@/components/shared/meeting-item.vue");

export default {
  name: "Overview",
  // components:{meetingItem},
  computed:{
     getPercentages(arr){
        return true;
    },
    userId: function (){ return this.$store.state.userId},
    user: function (){ return this.$store.state.user},
    proposals: function (){ return this.$store.state.proposals},
    proposalsLoaded: function (){ return this.$store.state.proposalsLoaded},
    sortedSkills: function(){
      function compare(a, b) {
        return a.name > b.name ? 1: -1
      }
      return this.user.skills.slice().sort(compare);
    },
  },
  data(){
    return{
      isLoaded: false,
      snackbar:{
        show:false,
      },
    }
  },
  watch:{
    '$route': 'getUser'
  },
  methods:{

  },
  mounted(){
    // this.getProposals();
  }
};
</script>


<style lang="scss">
.overview.component{
  // background-color: #f2f2f2;
  position: relative;
  .proposal{
    // border-radius: 6px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    &:hover{
      background: #f2f2f2;
      background: var(--v-primary-lighten5);
    }
    // background: var(--v-primary-base);
    // padding-bottom: -1px;
    .v-avatar{
      border: 2px solid transparent;
    }
    .overlap{
      margin-left: -8px;
      border:2px solid white;
    }
    .top-line{
      font-size: 18px;
      line-height: 22px;
      color: #444;
      font-weight: bold;
    }
    .chevron{
      max-width: 20px;
    }
  }

}

</style>
